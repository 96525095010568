$color-primary-black:black;
$color-primary-grey:lightgrey;
$color-secondary-white:white;
$color-secondary-lighter-grey:rgb(233, 233, 233);


$padding-mobile-sides: 1rem;

$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;
$mobile-breakpoint: 767px;
