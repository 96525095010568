@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.recent-holes {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;

  &__view-all-btn {
    text-decoration: none;
    background-color: $color-primary-grey;
    border-radius: 1rem;
    padding: 0 2rem;
    padding-bottom: 0.1rem;
    font-weight: 600;
    @include tablet {
      font-size: 1.5rem;
      margin: 1rem;
    }

    &:hover{
      color: $color-primary-grey;
      background-color: $color-primary-black;

  }
  }
  &__header {
    text-align: center;
    width: 100%;
    font-weight: 600;
    font-size: 1rem;
    padding: 1rem 0;
    padding-bottom: 0.25rem;
    margin-bottom: 1rem;
    color: $color-primary-grey;
    border-bottom: 1px solid $color-primary-grey;
    @include tablet{
      font-size: 1.5rem;
      margin-bottom: 1.5rem;

    }
  }
  &__container {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 2rem;
    @include desktop{
      padding: 0 5rem;
    }
  }
  &__sub-container {
    width: 35vw;
    height: 35vw;
    background-color: $color-primary-grey;
    border-radius: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include tablet{
      width: 25vw;
      height: 25vw;
    }
    @include desktop{
      width: 17vw;
      height: 17vw;
    }
  }
  &__text {
    color: $color-secondary-white;
    text-align: center;
    font-weight: 600;
    width: 95%;
    height: 95%;
    background-color: $color-primary-black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 8px rgb(255, 255, 255);
    @include tablet{
      font-size: 2rem;
    }
    &:hover {
      background-color: $color-secondary-white;
      color: $color-primary-black;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    }
  }
}
