@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;
.rb-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  &__form {
    padding-top: 1rem;
  }
  &__input-container {
    position: relative;
 

  }

  &__input {
    position: relative;
    padding: 1rem 2rem;
    border: 1px solid $color-primary-black;
    border-radius: 1rem;
    width: 80vw;
    text-align: center;
    margin-bottom: 2rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    font-size: 1.25rem;

    @include tablet {
      font-size: 1.5rem;
  }
  @include desktop{
    margin-top: 2rem;
    margin-bottom: 3rem;
    width: 75vw;
  }

  }
  &__btn {
    border: none;
    border: 1px solid $color-primary-black;
    border-radius: 1rem;
    position: absolute;
    top: 0;
    background-color: $color-secondary-lighter-grey;
    height: 3.55rem;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @include tablet {
      height: 3.75rem;
      width: 3.75rem;
      top: 0;
      right: 0;
      @include desktop{
        top: 2rem;
      }


  }

  }
  &__btn-img{
    height:100%;
    width: 100%;
  }
}
