@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.saved-links{
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: .75rem;
    &__header{
        display: flex;
        @include tablet{
            justify-content: center;
            
        }
    }
    &__header-link{
        display: flex;
        font-size: 1.5rem;
        align-items: center;
        text-decoration: none;
    }
&__container{
    border: 1px solid $color-primary-black;
    padding: .5rem;
    border-radius: 10px;
    background-color: $color-secondary-lighter-grey;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    &:hover{
        border: 2px solid $color-primary-black;


    }
}
&__sub-container{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
&__tunnel-name{
}
&__url{
    text-decoration: none;
    overflow-x: clip;

}
&__solid{
    border-top: solid 1px $color-primary-black;
}
&__header{
    display: flex;
    justify-content: center;
    gap: 50%;
    padding: 1rem;
}
}
