@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.nav-bar {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 1rem;
  border-bottom: 1px solid $color-primary-grey;
  &__menu-btn {
    position: absolute;
    left: 1rem;
    top: 25%;
    height: 3rem;
  }
  &__logo-bg {
    background-color: $color-primary-black;
    border-radius: 50%;
  }
  &__logo-txt {
    color: $color-secondary-white;
    padding: .75rem 1rem;

  }
  &__drop-down {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    z-index: 1;
    padding: 2rem 1rem;
    border: 1px solid $color-primary-black;
    background-color: $color-primary-grey;
    left: 0;
    top: 4.55rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
border-radius: 0 0 1rem 0;
    &--hidden{
        display: none;
    }
  }
  &__link{
    text-decoration:none;
    &.active{
        font-weight: bold;
        

    }
  }
}
