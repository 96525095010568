@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.search {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  &__rb-name {
    font-size: 1rem;
    padding: 0.5rem 0;
    font-weight: 600;
  }
  &__form {
  }
  &__input-container {
    position: relative;
  }

  &__input {
    position: relative;
    padding: 0.5rem;
    border: 1px solid $color-primary-black;
    border-radius: 0.25rem;
    width: 80vw;
    text-align: center;
    margin-bottom: 2rem;
  }
  &__btn {
    border: none;
    border: 1px solid $color-primary-black;
    border-radius: 4px;
    position: absolute;
    top: 5%;
    background-color: $color-secondary-lighter-grey;
    height: 1.75rem;
    right: 2.5px;
  }
  &__recent {
    display: flex;
    justify-content: center;
    &--hidden {
      display: none;
    }
  }
  &__recent-txt {
    color: $color-primary-grey;
    text-align: center;
  }
  &__previous {
    display: flex;
    justify-content: center;
    width: 80%;
    background-color: $color-secondary-lighter-grey;
    padding: 0.25rem 0;
    border-radius: 0.25rem;
    border: 1px solid $color-primary-grey;
    &--hidden {
      display: none;
    }
  }
}

.search-link {
    
  &__container {
    margin: 0 0.5rem;
    border: 1px solid $color-primary-black;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow-x: clip;
  }
  &__header {
    display: flex;
    gap: 0.5rem;
    flex-wrap: nowrap;
  }
  &__img {
    width: fit-content;
    height: 3rem;
  }
  &__img-title-container {
  }
  &__title {
  }
  &__url {
    display: flex;
    flex-wrap: wrap ;
    width: 100%;
    text-overflow: ellipsis ;
  }
  &__description-container {
  }
  &__description-header {
  }
  &__description {
  }
}

.search-container{
    width: 90%;
}