@font-face {
    font-family: "Avenir";
    src: url("../../asset/font/AvenirNextLTPro-Regular.otf") format("opentype"),
      url("../../asset/font/alternativeFiles/AvenirNextLTPro.ttf") format("truetype");
    font-weight: 400;
  }
  
  /* Demi */
  @font-face {
    font-family: "Avenir";
    src: url("../../asset/font/AvenirNextLTPro-Demi.otf") format("opentype"),
      url("../../asset/font/alternativeFiles/AvenirNextLTProDemi.ttf") format("truetype");
    font-weight: 600;
  }
  
  /* Bold */
  @font-face {
    font-family: "Avenir";
    src: url("../../asset/font/AvenirNextLTPro-Bold.otf") format("opentype"),
      url("../../asset/font/alternativeFiles/AvenirNextLTProBold.ttf") format("truetype");
    font-weight: 700;
  }