@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.new-hole{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    @include tablet{
        flex-direction: row;
        justify-content: center;
    }
    &__txt-box{
        width: 60%;
        padding: 1rem 2rem;
        border: 1px solid $color-primary-black;
        border-radius: 1rem;
        text-align: center;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        @include tablet {
            font-size: 1.5rem;
        }


        &:focus{
            outline: none;
            border: 2px solid $color-primary-black;
        }
    }
    &__btn{
        font-size: large;
        font-weight: bold;
        background-color: $color-primary-black;
        color: $color-secondary-white;
        padding: .5rem 4rem;
        border-radius: 1.5rem;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        &:hover{
            color: $color-primary-black;
            background-color: $color-secondary-white;
    
        }

    }
}