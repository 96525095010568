@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.signup-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    padding-top: .5rem;
    gap: .2rem;
&__label{
    padding-top: .35rem;
    font-size: 2rem;
}
&__input{
    padding: .25rem;
    margin: .25rem;
    width: 80%;
    height: 2.25rem;
    font-size: 1rem;
    border: 1px solid $color-primary-black;
    border-radius: 5px;

    @include tablet{
        width: 40%;
    }
}
&__login-btn{
    border: none;
    border: 1px solid $color-primary-black;
    margin-top: .75rem;
    padding:.25rem .5rem;
    border-radius: .5rem;
    font-size: 1.5rem;
    width: 80%;
}
&__signup-btn{
    border: none;
    border: 1px solid $color-primary-black;
    margin-top: .25rem;
    padding:.25rem .5rem;
    border-radius: .5rem;
    background-color: $color-primary-black;
    color: $color-secondary-white;
}
&__no-account{
    padding-top: 1rem;
}
}
.signup-contact{
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    gap: 0.25rem;
    justify-content: center;
    align-items: center;
&__text{
    text-align: center;
}
&__link{}
    
}
