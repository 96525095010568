@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  padding-top: 10vh;
  gap: 0.25rem;
  @include tablet {
    background-color: $color-primary-grey;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    margin: 20vh 10rem 0 10rem;
    border: 1px black solid;
    border-radius: 1rem;
  }
@include desktop{
    margin: 10vh 25rem 0 25rem;

}
  &__label {
    padding-top: 0.5rem;
    font-size: 2rem;
  }
  &__input {
    padding: 0.25rem;
    margin: 0.25rem;
    width: 80%;
    height: 2rem;
    font-size: 1rem;
    border: 1px solid $color-primary-black;
    border-radius: 5px;

    @include tablet {
      height: 2.5rem;
      font-size: 1.25rem;
      padding-left: 0.5rem;
    }
  }
  &__login-btn {
    border: none;
    border: 1px solid $color-primary-black;
    margin-top: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 1.5rem;
    width: 80%;
    &:hover{
      color: $color-primary-black;
      background-color: $color-secondary-white;
    }
    @include tablet {
      width: 50%;
      height: 2.5rem;
    }
  }
  &__signup-btn {
    border: none;
    border: 1px solid $color-primary-black;
    margin-top: 0.25rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    background-color: $color-primary-black;
    color: $color-secondary-white;
    &:hover{
      color: $color-primary-black;
      background-color: $color-secondary-white;
    }
  }
  &__no-account {
    padding-top: 1rem;
  }
}
.login-contact {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  &__text {
    text-align: center;
  }
  // &__link {
  // }
}
